document.addEventListener('DOMContentLoaded', () => {
  const img = document.getElementById('draggableImage');
  let isDragging = false;
  let offsetX, offsetY;

  function onMouseDown(e) {
    isDragging = true;
    offsetX = e.clientX - img.offsetLeft;
    offsetY = e.clientY - img.offsetTop;
    e.preventDefault(); // Prevent text selection
  }

  function onTouchStart(e) {
    isDragging = true;
    const touch = e.touches[0];
    offsetX = touch.clientX - img.offsetLeft;
    offsetY = touch.clientY - img.offsetTop;
    e.preventDefault(); // Prevent scrolling
  }

  function onMouseMove(e) {
    if (isDragging) {
      img.style.left = (e.clientX - offsetX) + 'px';
      img.style.top = (e.clientY - offsetY) + 'px';
    }
  }

  function onTouchMove(e) {
    if (isDragging) {
      const touch = e.touches[0];
      img.style.left = (touch.clientX - offsetX) + 'px';
      img.style.top = (touch.clientY - offsetY) + 'px';
    }
  }

  function stopDragging() {
    isDragging = false;
  }

  function checkScreenWidth() {
    if (window.innerWidth >= 600) {
      if (window.innerWidth > 1700) {
        removeDraggable(img);
        img.style.left = 'none';
        img.style.top = 'none';
      }
      makeDraggable(img);
    } else {
      removeDraggable(img);
    }
  }

  function makeDraggable(element) {
    element.addEventListener('mousedown', onMouseDown);
    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', stopDragging);
    document.addEventListener('mouseout', stopDragging);

    element.addEventListener('touchstart', onTouchStart, {passive: false});
    document.addEventListener('touchmove', onTouchMove);
    document.addEventListener('touchend', stopDragging);

    element.dataset.draggable = 'true';
  }

  function removeDraggable(element) {
    if (element.dataset.draggable !== 'true') {
      return;
    }

    element.removeEventListener('mousedown', onMouseDown);
    document.removeEventListener('mousemove', onMouseMove);
    document.removeEventListener('mouseup', stopDragging);
    document.removeEventListener('mouseout', stopDragging);

    element.removeEventListener('touchstart', onTouchStart);
    document.removeEventListener('touchmove', onTouchMove);
    document.removeEventListener('touchend', stopDragging);

    element.dataset.draggable = "false";
  }

  checkScreenWidth();
  window.addEventListener('resize', checkScreenWidth);
});
